<template>
  <div class="bg-white rounded">
    <div class="flex flex-row w-full bg-grey-light-3 py-1 pl-3 rounded">
      <div
        class="flex flex-row items-center focus:outline-none w-tableIndex mr-1"
      >
        <p class="text-sm-2 text-grey-light mr-0.4">NO.</p>
      </div>
      <div class="flex flex-grow max-w-2/5 mr-2">
        <button
          class="flex flex-row items-center focus:outline-none"
          data-testid="header-1"
          @click="() => sort('targetname', 0)"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">COMPETENCY</p>
          <img
            src="../../../../assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="rotationMatrix[0] !== 1 ? 'transform rotate-180' : null"
          />
        </button>
      </div>
      <div class="flex flex-grow max-w-1/4 mr-1">
        <button
          class="flex flex-row items-center focus:outline-none"
          data-testid="header-3"
          @click="() => sort('assessment', 2)"
        >
          <p class="text-sm-2 text-grey-light mr-0.4">ASSESSMENT</p>
          <img
            src="../../../../assets/img/delta-table/caret.svg"
            class="transition-all"
            :class="rotationMatrix[2] !== 1 ? 'transform rotate-180' : null"
          />
        </button>
      </div>
      <button
        class="flex flex-grow max-w-1/5 items-center justify-center ml-auto"
        data-testid="header-2"
        @click="() => sort('value', 1)"
      >
        <p class="text-sm-2 text-grey-light mr-0.4">{{ name.toUpperCase() }}</p>
        <img
          src="../../../../assets/img/delta-table/caret.svg"
          class="transition-all"
          :class="rotationMatrix[1] !== 1 ? 'transform rotate-180' : null"
        />
      </button>
    </div>
    <div>
      <VirtualList
        style="max-height: 360px; overflow-y: auto"
        :data-key="'competencyId'"
        :data-sources="mappedList"
        :data-component="itemComponent"
        :extra-props="{ disableAll, toggleDisabled }"
      />
    </div>
  </div>
</template>

<script>
import ListItem from "./ListItem.vue";
import VirtualList from "vue-virtual-scroll-list";
import plus from "@/assets/img/icons/add.svg";
import { mapState } from "vuex";

export default {
  name: "TargetsTable",
  components: { VirtualList },
  data: () => ({
    plus,
    keyword: "",
    itemComponent: ListItem,
    rotationMatrix: [1, 1, 1],
    mappedList: [],
    pageNr: 1
  }),
  props: { handler: { type: Function }, disableAll: Boolean },
  computed: {
    ...mapState({
      targets: (state) => state.companies.selectedJobRole.targets,
      name: (state) => state.companies.selectedJobRole.name,
    }),
  },
  watch: {
    mappedList() {
      this.handler(this.mappedList);
    },
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    sort(key, index) {
      this.resetRotationMatrix(index);
      switch (key) {
        case "jobrolename":
          this.mappedList.sort(
            (a, b) => this.rotationMatrix[index] * a.name.localeCompare(b.name)
          );
          break;
        case "status":
          this.mappedList.sort(
            (a, b) => this.rotationMatrix[index] * (a.value - b.value)
          );
          break;
        default:
          break;
      }
    },
  },
  mounted() {
    this.mappedList = this.targets.map((item) => {
      item.value = Number.parseFloat(item.value).toFixed(2);
      return item;
    });
  },
  methods: {
    toggleDisabled(val) {
      this.disableAll = val;
    },
    resetRotationMatrix(index) {
      this.rotationMatrix = this.rotationMatrix.map((item, idx) =>
        idx === index ? (item *= -1) : (item = 1)
      );
    },
    sort(key, index) {
      this.resetRotationMatrix(index);
      switch (key) {
        case "targetname":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] *
              a.competencyName.localeCompare(b.competencyName)
          );
          break;
        case "assessment":
          this.mappedList.sort(
            (a, b) =>
              this.rotationMatrix[index] *
              a.surveyName.localeCompare(b.surveyName)
          );
          break;
        case "value":
          this.mappedList.sort(
            (a, b) => this.rotationMatrix[index] * (a.value - b.value)
          );
          break;
        default:
          break;
      }
    },
  },
};
</script>
