<template>
  <div>
    <div class="flex flex-row w-full py-2 pl-3 items-center">
      <div class="flex w-tableIndex justify-center mr-1 pr-3">
        <p class="font-normal text-md text-grey-dark-2">
          {{ index + 1 }}
        </p>
      </div>
      <div class="flex flex-grow max-w-2/5 mr-2">
        <p class="flex flex-grow text-md text-grey-dark-1 font-semibold">
          {{ source.competencyName }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/4 mr-1">
        <p class="flex flex-grow text-md text-grey-dark-1 font-semibold">
          {{ source.surveyName }}
        </p>
      </div>
      <div class="flex flex-grow max-w-1/5 justify-center ml-auto">
        <input
          v-model="source.value"
          :disabled="disableAll && current"
          @input="
            () => {
              toggleDisabled(true);
              current = false;
              onChange(source.value);
            }
          "
          class="py-1 px-1.5 border rounded border-grey-light-1 font-semibold text-md text-grey-dark-1 w-targetInput text-center"
        />
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import Button from "../../../../components/Button/Button.vue";
import debounce from "lodash/debounce";

export default {
  components: { Button },
  name: "ListItem",
  data: () => ({ current: true }),
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    disableAll: { type: Boolean },
    toggleDisabled: { type: Function },
  },
  methods: {
    onChange: debounce(function(value) {
      let correctedValue = parseFloat(value);
      if (!correctedValue) {
        correctedValue = 0;
      } else if (correctedValue > 4) {
        correctedValue = 4;
      } else if (correctedValue < 0) {
        correctedValue = 0;
      }
      this.source.value = correctedValue.toFixed(2);
      this.current = true;
      this.toggleDisabled(false);
    }, 500),
  },
};
</script>
