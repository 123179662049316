<template>
  <div>
    <div class="flex">
      <div v-if="mode === 'create'" class="flex">
        <p class="font-barlow font-medium text-xl text-grey-medium-2">
          New Job Role /
        </p>
        <p class="font-barlow font-bold text-xl text-grey-dark-2 ml-1">
          Targets
        </p>
      </div>
      <div v-else class="flex">
        <p class="font-barlow font-medium text-xl text-grey-medium-2">
          Job Roles /
        </p>
        <p class="font-barlow font-bold text-xl text-grey-dark-2 ml-1">
          {{ selectedJobRole.name }}
        </p>
      </div>
      <div class="flex flex-grow justify-end">
        <Button
          text="Back"
          type="primary-white"
          size="medium"
          :iconLeft="arrowLeft"
          :rotateArrow="true"
          :onClick="goBack"
          class="mr-2"
          data-testid="back-button"
        />
      </div>
    </div>
    <div class="bg-white shadow rounded px-2 pt-3 mt-3 pb-4.5 mb-4">
      <div class="">
        <p class="font-barlow font-bold text-mdl text-grey-light mb-2">
          Benchmarks for Job Role
        </p>
        <div class="px-2">
          <p class="text-mdh text-grey-dark-1 max-w-3/5 mb-3">
            Benchmarks need to be set for the existing competencies specific to
            each assessment. All competencies need to be marked with benchmarks in
            order for that assessment to be updated with the new job role. You
            are able to enter a benchmark range between 0 and 4.
          </p>
          <TargetsTable :handler="handler" :disableAll=true />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import arrowLeft from "@/assets/img/icons/arrow-right.svg";
import TargetsTable from "../components/TargetsTable";
import { mapState } from "vuex";

export default {
  name: "JobRoleTargets",
  components: { TargetsTable },
  data: () => ({ arrowLeft, targets: [] }),
  computed: {
    ...mapState({
      mode: (state) => state.companies.jobRoleManagementMode,
      selectedJobRole: (state) => state.companies.selectedJobRole,
    })
  },
  methods: {
    handler(list) {
      this.targets = list;
    },
    goBack() {
      this.$router.push("/job-roles/management/general-information");
    }
  }
};
</script>
